<template>
  <div class="text-center">
    <h1 class="text-5xl mb-2">Contact</h1>
    <p class="mt-6 mb-2">Interested in hiring me?</p>
    <p>Feel free to write me an email at <a class="underline" href="mailto:benjamin@nater.net">benjamin@nater.net</a> or
      contact me through <a class="underline" href="https://www.linkedin.com/in/benjamin-nater/">LinkedIn</a>.
    </p>
  </div>
</template>

<script>
export default {
  name: 'Contact'
}
</script>

<style scoped>

</style>
